import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { startCase } from "lodash";

function Details(props){
  const { content, open, onClose } = props;
  const baseUrl = process.env.REACT_APP_API_CP_DOMAIN;
  return (
    <React.Fragment>
      <Modal isOpen= {open} toggle = {onClose} centered = {true} size = {"lg"}>
        <ModalHeader toggle = {onClose} tag="h4">
          {props.t("Bonus Details")}
        </ModalHeader>
        <ModalBody>

            
          {
            content?.type === "image" ? (<>
              <img
                src={`${baseUrl}/assets/${content.content}`}
                alt="Banner"
                style={{ width: "100%" }}
              />
            </>
              
            ) 
              : 
              <>
                <Row>
                  <strong>{content.Id ? "Id" : "" }</strong>
                  <p className="text-muted"> {content?.Id} </p>
                  {Object.keys(content?.content || {}).map((key) => (
                    <>
                      <div className={`col-${content?.content?.[key]?.length > 15 ? 12 : 6}`}>
                        <p className="mb-1">
                          <strong>{startCase(key ?? "")}</strong>
                        </p>
                        <p className="text-muted">{content?.content?.[key]}</p>
                      </div>
                    </>
                  ))}
                </Row>
              </>
          }
        </ModalBody>
      </Modal>
    
    </React.Fragment>
  );
}
export default withTranslation()(Details);