import * as axiosHelper from "../api_helper";
import qs from "qs";

export const getAllBonus = async ({ payload }) => {
  const paginationPayload = { 
    limit: payload.limit,
    page: payload.page
  };
  const data = await axiosHelper.get(`/bonus?${qs.stringify(paginationPayload)}`);
  if (data.isError){
    throw new Error(data.isError);
  }

  return data;
};

// export const postBonus = async ({ payload }) => {
//   const data = await axiosHelper.postFormData("/bonus", payload);
//   if (data.isError){
//     throw new Error(data.isError);
//   }

//   return data;
// };

// export const deleteBonus = async ({ payload }) => {  
//   const data = await axiosHelper.del(`/bonus/${payload}`);
//   if (data.isError){
//     throw new Error(data.isError);
//   }

//   return data;
// };

// export const updateBonus = async (  payload  ) => {
//   const { id, formData } = payload;
//   const data = await axiosHelper.updateFormData(`/bonus/${id}`, formData);
//   if (data.isError){
//     throw new Error(data.isError);
//   }

//   return data;
// };