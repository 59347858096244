import {
  REQ_IB_START,
  GET_IB_REQUEST_STATUS,
  CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
  CREATE_CHANGE_LEVERAGE_REQ_SUCCESS,
  CREATE_CHANGE_LEVERAGE_REQ_FAIL,
  POST_UNLINK_REQ_REQUESTED,
  POST_UNLINK_REQ_SUCCESS,
  POST_UNLINK_REQ_FAIL,
  POST_LINK_REQ_FAIL,
  POST_LINK_REQ_SUCCESS,
  POST_LINK_REQ_REQUESTED,
  REQ_INVESTOR_START,
  GET_INVESTOR_REQUEST_STATUS,
  REQ_MASTER_START,
  GET_MASTER_REQUEST_STATUS,
  REQ_BONUS_START,
  GET_SWAP_REQUEST_STATUS,
  GET_SWAP_FREE_REQUEST_STATUS,
  CREATE_CHANGE_SWAP_REQUESTED,
  CREATE_CHANGE_SWAP_SUCCESS,
  CREATE_CHANGE_SWAP_FAIL,
} from "./actionTypes";

export const requestPartnership = (params) => {
  return {
    type: REQ_IB_START,
    payload: params,
  };
};
export const requestBonus = (params) => {
  return {
    type: REQ_BONUS_START,
    payload: params,
  };
};

export const getIbRequestStatus = (params) => {
  return {
    type: GET_IB_REQUEST_STATUS,
    payload: params,
  };
};

// change leverage req
export const createChangeLeverageRequest = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
    payload: params,
  };
};
export const createChangeLeverageSuccess = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_SUCCESS,
    payload: params,
  };
};
export const createChangeLeverageFail = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_FAIL,
    payload: params,
  };
};

//unlink account
export const postUnlinkReqInit = (params) => {
  return {
    type: POST_UNLINK_REQ_REQUESTED,
    payload: params,
  };
};

export const postUnlinkReqSuccess = (params) => {
  return {
    type: POST_UNLINK_REQ_SUCCESS,
    payload: params,
  };
};

export const postUnlinkReqFail = (params) => {
  return {
    type: POST_UNLINK_REQ_FAIL,
    payload: params,
  };
};

//link account
export const postLinkReqInit = (params) => {
  return {
    type: POST_LINK_REQ_REQUESTED,
    payload: params,
  };
};

export const postLinkReqSuccess = (params) => {
  return {
    type: POST_LINK_REQ_SUCCESS,
    payload: params,
  };
};

export const postLinkReqFail = (params) => {
  return {
    type: POST_LINK_REQ_FAIL,
    payload: params,
  };
};

export const requestInvestor = (params) => {
  return {
    type: REQ_INVESTOR_START,
    payload: params,
  };
};

export const getInvestorRequestStatus = (params) => {
  return {
    type: GET_INVESTOR_REQUEST_STATUS,
    payload: params,
  };
};

export const requestMaster = (params) => {
  return {
    type: REQ_MASTER_START,
    payload: params,
  };
};

export const getMasterRequestStatus = (params) => {
  return {
    type: GET_MASTER_REQUEST_STATUS,
    payload: params,
  };
};

export const getSwapRequestStatus = (params) => {
  return {
    type: GET_SWAP_REQUEST_STATUS,
    payload: params,
  };
};

export const getSwapFreeRequestStatus = (params) => {
  return {
    type: GET_SWAP_FREE_REQUEST_STATUS,
    payload: params,
  };
};

export const createChangeSwapRequest = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_REQUESTED,
    payload: params,
  };
};
export const createChangeSwapSuccess = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_SUCCESS,
    payload: params,
  };
};
export const createChangeSwapFail = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_FAIL,
    payload: params,
  };
};
