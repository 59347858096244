import {
  requestPartnershipAPI,
  getRequestTypeAPI,
  postChangeLeverageReq,
  postUnlinkRequestAPI,
  postLinkRequestAPI,
  requestInvestorAPI,
  getRequestInvestorStatusAPI,
  requestMasterAPI,
  getRequestMasterStatusAPI,
  requestBonusAPI,
  postChangeSwapReq,
  getSwapFreeRequestTypeAPI,
  getSwapRequestTypeAPI,
} from "apis/forex/requests";
// eslint-disable-next-line object-curly-newline
import { put, call, takeEvery } from "redux-saga/effects";
import {
  showErrorNotification,
  showSuccessNotification,
} from "store/general/notifications/actions";
import {
  REQ_IB_START,
  REQ_IB_SUCCESS,
  GET_IB_REQUEST_STATUS,
  REQ_IB_FAIL,
  GET_IB_REQUEST_STATUS_SUCCESS,
  GET_IB_REQUEST_STATUS_FAIL,
  CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
  POST_UNLINK_REQ_REQUESTED,
  POST_LINK_REQ_REQUESTED,
  REQ_INVESTOR_FAIL,
  REQ_INVESTOR_SUCCESS,
  REQ_INVESTOR_START,
  GET_INVESTOR_REQUEST_STATUS,
  GET_INVESTOR_REQUEST_STATUS_FAIL,
  GET_INVESTOR_REQUEST_STATUS_SUCCESS,
  REQ_MASTER_START,
  GET_MASTER_REQUEST_STATUS,
  REQ_MASTER_SUCCESS,
  REQ_MASTER_FAIL,
  GET_MASTER_REQUEST_STATUS_SUCCESS,
  GET_MASTER_REQUEST_STATUS_FAIL,
  REQ_BONUS_START,
  REQ_BONUS_SUCCESS,
  REQ_BONUS_FAIL,
  CREATE_CHANGE_SWAP_REQUESTED,
  GET_SWAP_FREE_REQUEST_STATUS,
  GET_SWAP_REQUEST_STATUS,
  GET_SWAP_REQUEST_STATUS_SUCCESS,
  GET_SWAP_REQUEST_STATUS_FAIL,
  GET_SWAP_FREE_REQUEST_STATUS_SUCCESS,
  GET_SWAP_FREE_REQUEST_STATUS_FAIL,
} from "./actionTypes";
import {
  createChangeLeverageSuccess,
  createChangeLeverageFail,
  postUnlinkReqFail,
  postUnlinkReqSuccess,
  postLinkReqSuccess,
  postLinkReqFail,
  requestBonus,
  createChangeSwapSuccess,
  createChangeSwapFail,
} from "./actions";
import { toggleCurrentModal } from "store/actions";

function* ReqIB({ payload }) {
  try {
    const result = yield call(requestPartnershipAPI, payload);
    if (result.status)
      yield put({
        type: REQ_IB_SUCCESS,
        payload: result,
      });
    else {
      yield put({
        type: REQ_IB_FAIL,
      });
      yield put(showErrorNotification(result?.message));
    }
  } catch (error) {
    yield put({
      type: REQ_IB_FAIL,
    });
    yield put(showErrorNotification(error.message));
  }
}

function* ReqBonus({ payload }) {
  try {
    const result = yield call(requestBonusAPI, payload);
    if (result.isSuccess){
      yield put({
        type: REQ_BONUS_SUCCESS,
        payload: result,
      });
      yield put(
        showSuccessNotification("Bonus request was sent successfully")
      );
    }
    else {
      yield put({
        type: REQ_BONUS_FAIL,
      });
      yield put(showErrorNotification(result?.message));
    }
  } catch (error) {
    yield put({
      type: REQ_BONUS_FAIL,
    });
    yield put(showErrorNotification(error.message));
  }
}

function* getRequestType({ payload }) {
  const { result } = yield call(getRequestTypeAPI, payload);
  if (result)
    yield put({
      type: GET_IB_REQUEST_STATUS_SUCCESS,
      payload: result,
    });
  yield put({
    type: GET_IB_REQUEST_STATUS_FAIL,
    payload: result,
  });
}

function* createChangeLeverageReq({ payload }) {
  try {
    const result = yield call(postChangeLeverageReq, payload);
    yield put(createChangeLeverageSuccess(result));
    yield put(
      showSuccessNotification("Change leverage request was sent successfully")
    );
    yield put(toggleCurrentModal(""));
  } catch (error) {
    yield put(createChangeLeverageFail(error));
    yield put(showErrorNotification(error.message));
  }
}

function* postUnlinkAccountReq({ payload }) {
  try {
    const result = yield call(postUnlinkRequestAPI, payload);
    if (result.isSuccess) {
      yield put(postUnlinkReqSuccess(result));
      yield put(
        showSuccessNotification("Unlink investor request was sent successfully")
      );
      yield put(toggleCurrentModal(""));
    } else {
      throw new Error(result.message);
    }
  } catch (error) {
    yield put(postUnlinkReqFail(error));
    yield put(showErrorNotification(error.message));
  }
}

function* postLinkAccountReq({ payload }) {
  try {
    const result = yield call(postLinkRequestAPI, payload);
    if (result.isSuccess) {
      yield put(postLinkReqSuccess(result));
      yield put(
        showSuccessNotification("Link investor request was sent successfully")
      );
      yield put(toggleCurrentModal(""));
    } else {
      showSuccessNotification(result.message);
    }
  } catch (error) {
    yield put(postLinkReqFail(error));
    yield put(showErrorNotification(error.message));
  }
}

function* ReqInvestor({ payload }) {
  try {
    const result = yield call(requestInvestorAPI, payload);
    if (result.status)
      yield put({
        type: REQ_INVESTOR_SUCCESS,
        payload: result,
      });
    else {
      yield put({
        type: REQ_INVESTOR_FAIL,
      });
      yield put(showErrorNotification(result?.message));
    }
  } catch (error) {
    yield put({
      type: REQ_INVESTOR_FAIL,
    });
    yield put(showErrorNotification(error.message));
  }
}

function* getRequestInvestorStatus({ payload }) {
  const { result } = yield call(getRequestInvestorStatusAPI, payload);
  if (result)
    yield put({
      type: GET_INVESTOR_REQUEST_STATUS_SUCCESS,
      payload: result,
    });
  yield put({
    type: GET_INVESTOR_REQUEST_STATUS_FAIL,
    payload: result,
  });
}

function* ReqMaster({ payload }) {
  try {
    const result = yield call(requestMasterAPI, payload);
    if (result.status){
      yield put({
        type: REQ_MASTER_SUCCESS,
        payload: result,
      });
      yield put(showSuccessNotification("Request sent successfully"));
    } else {
      yield put({
        type: REQ_MASTER_FAIL,
      });
      yield put(showErrorNotification(result?.message));
    }
  } catch (error) {
    yield put({
      type: REQ_MASTER_FAIL,
    });
    yield put(showErrorNotification(error.message));
  }
}

function* getRequestMasterStatus({ payload }) {
  const { result } = yield call(getRequestMasterStatusAPI, payload);
  if (result)
    yield put({
      type: GET_MASTER_REQUEST_STATUS_SUCCESS,
      payload: result,
    });
  yield put({
    type: GET_MASTER_REQUEST_STATUS_FAIL,
    payload: result,
  });
}

function* getSwapRequestType({ payload }) {
  const { result } = yield call(getSwapRequestTypeAPI, payload);
  if (result)
    yield put({
      type: GET_SWAP_REQUEST_STATUS_SUCCESS,
      payload: result,
    });
  yield put({
    type: GET_SWAP_REQUEST_STATUS_FAIL,
    payload: result,
  });
}
function* getSwapFreeRequestType({ payload }) {
  const { result } = yield call(getSwapFreeRequestTypeAPI, payload);
  if (result)
    yield put({
      type: GET_SWAP_FREE_REQUEST_STATUS_SUCCESS,
      payload: result,
    });
  yield put({
    type: GET_SWAP_FREE_REQUEST_STATUS_FAIL,
    payload: result,
  });
}
function* createChangeSwapReq({ payload }) {
  try {
    const result = yield call(postChangeSwapReq, payload);
    yield put(createChangeSwapSuccess(result));
    yield put(
      showSuccessNotification("Change swap request was sent successfully")
    );
    yield put(toggleCurrentModal(""));
    // setTimeout(() => {
    //   window.location.reload();
    // }, 3000);
  } catch (error) {
    yield put(createChangeSwapFail(error));
    yield put(showErrorNotification(error.message));
  }
}

function* requests() {
  yield takeEvery(REQ_IB_START, ReqIB);
  yield takeEvery(REQ_BONUS_START, ReqBonus);
  yield takeEvery(GET_IB_REQUEST_STATUS, getRequestType);
  yield takeEvery(
    CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
    createChangeLeverageReq
  );
  yield takeEvery(POST_UNLINK_REQ_REQUESTED, postUnlinkAccountReq);
  yield takeEvery(POST_LINK_REQ_REQUESTED, postLinkAccountReq);
  yield takeEvery(REQ_INVESTOR_START, ReqInvestor);
  yield takeEvery(GET_INVESTOR_REQUEST_STATUS, getRequestInvestorStatus);
  yield takeEvery(REQ_MASTER_START, ReqMaster);
  yield takeEvery(GET_MASTER_REQUEST_STATUS, getRequestMasterStatus);
  yield takeEvery(
    CREATE_CHANGE_SWAP_REQUESTED,
    createChangeSwapReq
  );
  yield takeEvery(GET_SWAP_FREE_REQUEST_STATUS, getSwapFreeRequestType);
  yield takeEvery(GET_SWAP_REQUEST_STATUS, getSwapRequestType);
}

export default requests;
