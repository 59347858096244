import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { 
  useDispatch, connect, useSelector
} from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { requestBonus } from "store/actions";


function ApplyBonus(props) {
  const dispatch = useDispatch();
  const [showModal, toggleModal] = useModal(false);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const { accounts, loading: accountLoading } = useSelector((state) => state.forex?.accounts);

 
  const handleSubmit = (e, v) => {
    e.preventDefault();
    dispatch(requestBonus(
      {
        tradingAccountId:selectedAccount._id,
        platform:selectedAccount.platform,
        bonusId:props.bonus._id
      }));
  };
  console.log(selectedAccount);
  return (
    <>
      <Link to="#" className= "btn btn-primary d-none"  onClick={props.toggleModal}> Apply Bonus</Link>
      <Modal isOpen={props.showModal} toggle={props.toggleModal} centered={true}>
        <ModalHeader toggle={props.toggleModal} tag="h4">
          Apply Bonus
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
         
            {/* <AvFieldSelect
              name="bonusId"
              label="Bonus Name"
              placeholder="Select Bonus Name"
              options={props.docs?.filter((type) => type.expiration === "valid").map((type) => ({
                value: type._id,
                label: type.bonusName
              }))}
              value={props.docs}
              onChange={(e) =>{}}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Bonus Name is required"
                }
              }}
            /> */}
            <div>
              <Label>{props.t("Bonus Name")}</Label>
              <AvField
                type="text"
                name="bonusName"
                value={props.bonus?.bonusName}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <AvFieldSelect
              name="tradingAccountId"
              label="Account Group"
              placeholder="Select Account Group Options"
              options={accounts ?.filter((x)=> !x.bonusId) ?.map((account) => ({
                value: account,
                label: `${account.login} (${account?.accountTypeId?.title})`,
              }))} 

              onChange={(e) => {
                setSelectedAccount(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Leverage options is required"
                }
              }}
            />
          
            <div className='text-center mt-3 p-2'>
              {
                 
                <Button disabled={props.addLoading} onClick={props.toggleModal} type="submit" color="primary">
                    Add
                </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}
export default ApplyBonus;