import { combineReducers } from "redux";

import generalReducers from "./general/reducers";
import cryptoReducer from "./crypto/reducers";
import forexReducer from "./forex/reducers";
import mmReducer from "./mm/reducers";
import goldReducer from "./gold/reducers";
import walletReducer from "./wallets/reducer";
import bonusReducer from "./forex/bonus/reducer";

import { LOGOUT_USER } from "./general/auth/login/actionTypes";


const appReducers = combineReducers({
  ...generalReducers,
  shared: {}, // shared business logic between crypto and forex
  crypto: cryptoReducer,
  forex: forexReducer,
  mm: mmReducer,
  goldReducer,
  walletReducer,
  bonusReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    localStorage.removeItem("persist:Layout");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("IbOrClient");
    localStorage.removeItem("PORTAL");
    localStorage.removeItem("SUB_PORTAL");
    return appReducers({}, action);
  }
  return appReducers(state, action);
};


export default rootReducer;