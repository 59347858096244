import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import Select from "react-select";

import { fetchAgreements, getAccountTypesStart } from "store/actions";
import PageHeader2 from "components/Forex/Common/pageHeader2";
import DedicatedLinks from "../Partnership/Partnership/DedicatedLinks";
// import AgreementDetails from "./AgreementDetails";
import IbNotApproved from "components/Common/IbNotApproved";
import RequestBonus from "./RequestBonus";
import { fetchBonus } from "store/forex/bonus/actions";
import { getAccountsStart } from "store/forex/accounts/actions";
import PageHeader from "components/Forex/Common/PageHeader";
import CardWrapper from "components/Common/CardWrapper";

function Bonus() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const  clientData  = useSelector((state) => state.Profile?.clientData);
  const bonusDetails = useSelector((state)=> state.bonusReducer.docs);
  const { accounts, loading: accountLoading } = useSelector((state) => state.forex?.accounts);

  useEffect(() => {
    loadData();
  }, []);


  const result = bonusDetails?.filter((bonus)=> bonus.expiration === "valid");
  const finalResult = accounts?.map((a)=>{
    let trade = result?.map((s)=>{
      if (a.accountTypeId._id === s.accountType) {
        // console.log("dddd", s);
        return s; // Return the value if it meets the condition
      }
    });

    trade = trade?.filter((item) => item !== undefined);

    return trade;  
  });
  const flattenedResult = finalResult?.flat();

  console.log("SSS", accounts);
  // console.log("zzzz", result);
  
  function loadData(){
    // console.log("hello");
    dispatch(getAccountsStart());
    dispatch(fetchBonus());
  }
  return (
    <>
      <MetaTags>
        <title>{t("Bonus")}</title>
      </MetaTags>
      <div className="page-content text-center">
        <Container className="mt-1">
          <CardWrapper className="p-4 glass-card shadow-lg my-5">
            <div className="d-flex justify-content-center mb-3 mt-3">
              <h1 className="color-primary">Bonus</h1>
            </div>
            <Row className="mt-1 g-4">
              { flattenedResult?.map((bonus, indx) => <RequestBonus key={indx}
                bonusDetails={bonus}
              />) }
            </Row>
          </CardWrapper>
        </Container>
      </div>
    </>
  );
}

export default Bonus;