import QueryString from "qs";
import * as axiosHelper from "../api_helper";

export const requestPartnershipAPI = async () => {
  try {
    const result = await axiosHelper.post("/requests/ib");
    if (result.status) return result;
    else throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const requestBonusAPI = async (payload) => {
  try {
    const result = await axiosHelper.post("/requests/bonus/request", payload);
    if (result.status) return result;
    else throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRequestTypeAPI = async () => {
  try {
    return await axiosHelper.get("/requests/ib");
  } catch (error) {}
};

export const postChangeLeverageReq = async (payload) => {
  const result = await axiosHelper.post("/requests/change-leverage", payload);
  if (result.status) {
    return result;
  } else {
    throw new Error(result.message);
  }
};

export const createAccountRequestAPI = async (params) => {
  try {
    return await axiosHelper.post("/requests/account", params);
  } catch (error) {
    throw new Error("Error while sending new account Request");
  }
};

export const postUnlinkRequestAPI = async (params) => {
  try {
    return await axiosHelper.post("/requests/unlink", params);
  } catch (error) {
    throw new Error("Error while sending unlink investor request");
  }
};

export const postLinkRequestAPI = async ({ params }) => {
  try {
    const result = await axiosHelper.post("/requests/link", params);
    if (result.status) return result;
    else throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message || "Error while sending link investor request");
  }
};

export const requestInvestorAPI = async () => {
  try {
    const result = await axiosHelper.post("/requests/investor");
    if (result.status) return result;
    else throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRequestInvestorStatusAPI = async () => {
  try {
    return await axiosHelper.get("/requests/investor");
  } catch (error) {}
};

export const requestMasterAPI = async (params) => {
  try {
    const result = await axiosHelper.post("/requests/master", params);
    if (result.status) return result;
    else throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRequestMasterStatusAPI = async () => {
  try {
    return await axiosHelper.get("/requests/master");
  } catch (error) {}
};


export const getPendingRequestAPI = async (payload) => {
  try {
    return await axiosHelper.get(
      `/requests/investor/pending?${QueryString.stringify(payload)}`
    );
  } catch (error) {
    throw new Error("Error while fetching Requests");
  }
};

export const getSwapRequestTypeAPI = async () => {
  try {
    return await axiosHelper.get("/requests/swap");
  } catch (error) {}
};
export const getSwapFreeRequestTypeAPI = async () => {
  try {
    return await axiosHelper.get("/requests/swap-free");
  } catch (error) {}
};
export const postChangeSwapReq = async (payload) => {
  const result = await axiosHelper.post("/requests/change-swap", payload);
  if (result.status) {
    return result;
  } else {
    throw new Error(result.message);
  }
};