import {
  ADD_BONUS_CLEAR,
  ADD_BONUS_FAIL,
  ADD_BONUS_REQUESTED,
  ADD_BONUS_SUCCESS,


  FETCH_BONUS_FAIL,
  FETCH_BONUS_REQUESTED,
  FETCH_BONUS_SUCCESS,


  DELETE_BONUS_REQUESTED,
  DELETE_BONUS_SUCCESS,
  DELETE_BONUS_FAIL,


  EDIT_BONUS_REQUESTED,
  EDIT_BONUS_SUCCESS,
  EDIT_BONUS_FAIL,
  EDIT_BONUS_CLEAR
} from "./actionTypes";

// fetch bonus details
export const fetchBonus = (params = {}) => {
  return {
    type: FETCH_BONUS_REQUESTED,
    payload: params
  };
};
export const fetchBonusSuccess = (data) => {
  return {
    type: FETCH_BONUS_SUCCESS,
    payload: data
  };
};
export const fetchBonusFail = (error) => {
  return {
    type: FETCH_BONUS_FAIL,
    payload: { error }
  };
};

// add Bonus
// export const addBonus = (params = {}) => {
//   return {
//     type: ADD_BONUS_REQUESTED,
//     payload: params
//   };
// };
// export const addBonusSuccess = (data) => {
//   return {
//     type: ADD_BONUS_SUCCESS,
//     payload: data
//   };
// };
// export const addBonusFail = (error) => {
//   return {
//     type: ADD_BONUS_FAIL,
//     payload: { error }
//   };
// };
// export const addBonusClear = (data) => {
//   return {
//     type: ADD_BONUS_CLEAR,
//     payload: data
//   };
// };

// // delete Bonus
// export const deleteBonus = (params) => {
//   return {
//     type: DELETE_BONUS_REQUESTED,
//     payload: params
//   };
// };
// export const deleteBonusSuccess = (data) => {
//   return {
//     type: DELETE_BONUS_SUCCESS,
//     payload: data
//   };
// };
// export const deleteBonusFail = (error) => {
//   return {
//     type: DELETE_BONUS_FAIL,
//     payload: { error }
//   };
// };

// // edit Bonus
// export const editBonus = (params) => {
//   return {
//     type: EDIT_BONUS_REQUESTED,
//     payload: params
//   };
// };
// export const editBonusSuccess = (data) => {
//   return {
//     type:  EDIT_BONUS_SUCCESS,
//     payload: data
//   };
// };
// export const editBonusFail = (error) => {
//   return {
//     type:  EDIT_BONUS_FAIL,
//     payload: { error }
//   };
// };
// export const editBonusClear = (data) => {
//   return {
//     type: EDIT_BONUS_CLEAR,
//     payload: data
//   };
// };